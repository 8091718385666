<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
    [opened]="false"
  >
    <mat-toolbar color="primary" [class.mat-elevation-z6]="true"> </mat-toolbar>
    <div class="sidebar-icons-flex">
      <mat-nav-list class="sidebar-icons">
        <a
          mat-list-item
          routerLink="/"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          aria-label="Home link icon"
          (click)="closeDrawer(drawer)"
        >
          <div class="icon-text-container">
            <mat-icon>home</mat-icon>
            <span>Home</span>
          </div>
        </a>
        <a mat-list-item routerLink="/support-us" routerLinkActive="active" aria-label="Support us link icon" (click)="closeDrawer(drawer)">
          <div class="icon-text-container">
            <mat-icon color="accent">volunteer_activism</mat-icon>
            <span>Support AFM</span>
          </div>
        </a>
        <div class="menu-title">MARKET</div>
        <a mat-list-item routerLink="/pricecheck" routerLinkActive="active" aria-label="Price Check link icon" (click)="closeDrawer(drawer)">
          <div class="icon-text-container">
            <mat-icon>price_check</mat-icon>
            <span>Market Prices</span>
          </div>
        </a>
        <a mat-list-item routerLink="/pricefavorites" routerLinkActive="active" aria-label="Price Favorites link" (click)="closeDrawer(drawer)">
          <div class="icon-text-container">
            <mat-icon>favorite</mat-icon>
            <span>Favorite Prices</span>
          </div>
        </a>
        <a mat-list-item routerLink="/topitems" routerLinkActive="active" aria-label="Top Items link icon" (click)="closeDrawer(drawer)">
          <div class="icon-text-container">
            <mat-icon>storefront</mat-icon>
            <div style="display: flex; flex-direction: column">
              <p style="padding: 0px 0px; margin-bottom: 2px">Top Traded</p>
              <p style="padding: 0px 0px; margin-bottom: 0px">Items</p>
            </div>
          </div>
        </a>
        <div class="menu-title">TRADING</div>
        <a mat-list-item routerLink="/flipper" routerLinkActive="active" aria-label="Flipper link icon" (click)="closeDrawer(drawer)">
          <div class="icon-text-container premium-badge">
            <mat-icon>currency_exchange</mat-icon>
            <span>Market Flipper</span>
          </div>
        </a>
        <a mat-list-item routerLink="/yourflips" routerLinkActive="active" aria-label="Your Flips link icon" (click)="closeDrawer(drawer)">
          <div class="icon-text-container">
            <mat-icon>savings</mat-icon>
            <span>Your Flips</span>
          </div>
        </a>
        <a mat-list-item routerLink="/traderoutes" routerLinkActive="active" aria-label="Trade Routes link icon" (click)="closeDrawer(drawer)">
          <div class="icon-text-container premium-badge">
            <mat-icon>directions_boat</mat-icon>
            <span>Trade Routes</span>
          </div>
        </a>
        <a mat-list-item routerLink="/transport" routerLinkActive="active" aria-label="Transport link icon" (click)="closeDrawer(drawer)">
          <div class="icon-text-container">
            <mat-icon>local_shipping</mat-icon>
            <span>Transport</span>
          </div>
        </a>
        <div class="menu-title">CRAFTING</div>
        <a mat-list-item routerLink="/crafting" routerLinkActive="active" aria-label="Crafting link icon" (click)="closeDrawer(drawer)">
          <div class="icon-text-container">
            <mat-icon>handyman</mat-icon>
            <div style="display: flex; flex-direction: column">
              <p style="padding: 0px 0px; margin-bottom: 2px">Crafting &</p>
              <p style="padding: 0px 0px; margin-bottom: 0px">Refining</p>
            </div>
          </div>
        </a>
        <a mat-list-item routerLink="/crafts" routerLinkActive="active" aria-label="Crafts link" (click)="closeDrawer(drawer)">
          <div class="icon-text-container">
            <mat-icon>toc</mat-icon>
            <span>Your Crafts</span>
          </div>
        </a>
        <a mat-list-item routerLink="/farming" routerLinkActive="active" aria-label="Crafts link" (click)="closeDrawer(drawer)">
          <div class="icon-text-container premium-badge">
            <!-- <div class="banner left small">BETA</div> -->
            <mat-icon>agriculture</mat-icon>
            <div style="display: flex; flex-direction: column">
              <p style="padding: 0px 0px; margin-bottom: 2px">Farm & Breed</p>
              <p style="padding: 0px 0px; margin-bottom: 0px">Calculator</p>
            </div>
          </div>
        </a>
        <a mat-list-item routerLink="/journals" routerLinkActive="active" aria-label="Journals link icon" (click)="closeDrawer(drawer)">
          <div class="icon-text-container">
            <mat-icon>newspaper</mat-icon>
            <div style="display: flex; flex-direction: column">
              <p style="padding: 0px 0px; margin-bottom: 2px">Journals</p>
              <p style="padding: 0px 0px; margin-bottom: 0px">Calculator</p>
            </div>
          </div>
        </a>
        <div class="menu-title">PVP & META</div>
        <a mat-list-item routerLink="/metaitems" routerLinkActive="active" aria-label="Meta Items link icon" (click)="closeDrawer(drawer)">
          <div class="icon-text-container">
            <mat-icon>trending_up</mat-icon>
            <span>Meta Items</span>
          </div>
        </a>
        <a mat-list-item routerLink="/itempower" routerLinkActive="active" aria-label="Item Power link icon" (click)="closeDrawer(drawer)">
          <div class="icon-text-container">
            <mat-icon>shield</mat-icon>
            <span>Item Power</span>
          </div>
        </a>
        <a mat-list-item routerLink="/repaircost" routerLinkActive="active" aria-label="Repair Cost link icon" (click)="closeDrawer(drawer)">
          <div class="icon-text-container">
            <mat-icon>construction</mat-icon>
            <span>Repair Cost</span>
          </div>
        </a>
        <div class="menu-title">KNOWLEDGE</div>
        <a mat-list-item routerLink="/articles" routerLinkActive="active" aria-label="Articles link icon" (click)="closeDrawer(drawer)">
          <div class="icon-text-container">
            <mat-icon>school</mat-icon>
            <div style="display: flex; flex-direction: column">
              <p style="padding: 0px 0px; margin-bottom: 2px">Articles &</p>
              <p style="padding: 0px 0px; margin-bottom: 0px">Tutorials</p>
            </div>
          </div>
        </a>
        <a mat-list-item routerLink="/albion-map" routerLinkActive="active" aria-label="Albion Online Map link icon" (click)="closeDrawer(drawer)">
          <div class="icon-text-container">
            <!-- <div class="banner left small">NEW</div> -->
            <mat-icon>map</mat-icon>
            <span>Map</span>
          </div>
        </a>
        <a mat-list-item routerLink="/identifiers" routerLinkActive="active" aria-label="Identifiers link icon" (click)="closeDrawer(drawer)">
          <div class="icon-text-container">
            <mat-icon>fingerprint</mat-icon>
            <span>Client Identifier</span>
          </div>
        </a>
        <a mat-list-item routerLink="/changelog" routerLinkActive="active" aria-label="Change Log link icon" (click)="closeDrawer(drawer)">
          <div class="icon-text-container">
            <mat-icon>sync_alt</mat-icon>
            <span>Change Log</span>
          </div>
        </a>
        <a mat-list-item routerLink="/feedback" routerLinkActive="active" aria-label="Feedback Contact link icon" (click)="closeDrawer(drawer)">
          <div class="icon-text-container">
            <mat-icon>phone</mat-icon>
            <div style="display: flex; flex-direction: column">
              <p style="padding: 0px 0px; margin-bottom: 2px">Feedback</p>
              <p style="padding: 0px 0px; margin-bottom: 0px">Contact</p>
            </div>
          </div>
        </a>
      </mat-nav-list>
      <div class="sidebar-bottom">
        <mat-divider class="mt5"></mat-divider>
        <p class="market-data-text">
          Market data gently provided<br />by
          <a href="https://www.albion-online-data.com/" target="_blank"> The Albion Data Project </a>
        </p>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <mat-toolbar class="mat-toolbar" color="primary" [class.mat-elevation-z6]="true">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <!-- *ngIf="isHandset$ | async"> -->
        <mat-icon aria-label="Side nav toggle icon">{{ drawer.opened ? 'menu_open' : 'menu' }}</mat-icon>
      </button>
      <div [ngClass]="drawer.opened && (isHandset$ | async) ? 'display-none' : 'display-inline'" class="app-title">
        <span>{{ appTitle }}</span>
        <span class="server-badge" role="button" [innerText]="getServerName(_userSettingsService.getUserServer())" [matMenuTriggerFor]="serverMenu"></span>
      </div>
      <div class="flex-spacer"></div>
      <!-- Avatar -->
      @if (user) {
        <button mat-icon-button [matMenuTriggerFor]="userMenu" aria-label="User Menu">
          <img style="height: 100%; width: 100%; border-radius: 50%" matListItemAvatar [src]="user.photoURL" alt="User Avatar" />
        </button>
      }
      @if (!user) {
        <button mat-button (click)="loginWithGoogle()" [disabled]="authService.isLoggingIn" aria-label="User Menu">
          <mat-icon>login</mat-icon>
          LOGIN
        </button>
      }
      <!-- MARK: User Menu -->
      <mat-menu #userMenu="matMenu" xPosition="before" yPosition="below">
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Logout {{ userInitials }}</span>
        </button>
        @if (afmBeService.afmStripeUserData().showableSubscriptions.length > 0) {
          <button mat-menu-item (click)="router.navigate(['/', 'support-us'])">
            <mat-icon>subscriptions</mat-icon>
            <span>Manage Subscription</span>
          </button>
        }
        <button mat-menu-item [matMenuTriggerFor]="dangerMenu">
          <mat-icon>warning</mat-icon>
          <span>Danger Zone</span>
        </button>
      </mat-menu>
      <!-- MARK: Danger Zone Menu -->
      <mat-menu #dangerMenu="matMenu" xPosition="before" yPosition="below">
        <button mat-menu-item (click)="deleteAccount()">
          <span>Delete {{ userInitials }}'s account</span>
        </button>
      </mat-menu>
      <!-- MARK: Discord Link -->
      <a mat-icon-button aria-label="Discord Link" href="https://discord.com/invite/BPmDE3zznH" target="_blank">
        <img style="height: 100%; width: 100%; border-radius: 50%" matListItemAvatar src="assets/img/discordicon.webp" alt="Discord Link" />
      </a>
      <!-- MARK: Options Menu -->
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Options Menu">
        <mat-icon [matBadge]="getServerName(_userSettingsService.getUserServer()).slice(0, 2).toLowerCase()" matBadgeColor="accent" matBadgeSize="medium"
          >settings</mat-icon
        >
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="serverMenu">
          <mat-icon>language</mat-icon>
          <span>Server: {{ getServerName(_userSettingsService.getUserServer()) }}</span>
        </button>
        <button mat-menu-item [matMenuTriggerFor]="languageMenu">
          <mat-icon>translate</mat-icon>
          <span>{{ _userSettingsService.getUserLanguage().name }}</span>
        </button>
        <button mat-menu-item [matMenuTriggerFor]="themeMenu">
          <mat-icon>format_color_fill</mat-icon>
          <span>{{ _userSettingsService.getUserTheme() === 'albion-dark-theme' ? 'Dark Theme' : 'Light Theme' }}</span>
        </button>
        <button mat-menu-item (click)="showConsentDialog()">
          <mat-icon>cookie</mat-icon>
          <span>Cookie Settings</span>
        </button>
        <!-- <button mat-menu-item (click)="showCookieRevoke()">
          <mat-icon>cookie</mat-icon>
          <span>Cookie Settings</span>
        </button> -->
      </mat-menu>
      <!-- Server Menu -->
      <mat-menu #serverMenu="matMenu" xPosition="before" yPosition="below">
        @for (server of servers; track $index) {
          <button mat-menu-item (click)="serverSelected(server.id)">{{ server.name }}</button>
        }
      </mat-menu>
      <!-- Language Menu -->
      <mat-menu #languageMenu="matMenu" xPosition="before" yPosition="below">
        @for (language of languages; track language) {
          <button mat-menu-item (click)="languageSelected(language)">{{ language.name }}</button>
        }
      </mat-menu>
      <!-- Theme Menu -->
      <mat-menu #themeMenu="matMenu" xPosition="before" yPosition="below">
        <button mat-menu-item (click)="changeColorMode()">
          {{ _userSettingsService.getUserTheme() === 'albion-dark-theme' ? 'Light Theme' : 'Dark Theme' }}
        </button>
      </mat-menu>
    </mat-toolbar>
    <div class="loading-bar-container" [class.mobile]="isHandset$ | async">
      <mat-progress-bar mode="indeterminate" *ngIf="loadingBarService.isLoading()"></mat-progress-bar>
    </div>
    <div class="content-container">
      <div class="outlet">
        <div style="min-height: 100%">
          <router-outlet></router-outlet>
        </div>
        <mat-divider style="margin-top: 20px; margin-bottom: 10px"></mat-divider>
        <div style="width: 100%; display: flex; flex-direction: column; align-items: center">
          <div class="footer">
            <div class="footer-column">
              <p><b>Crafting</b></p>
              <p><a routerLink="/crafting">Crafting calculator</a></p>
              <p><a routerLink="/crafting">Refining calculator</a></p>
              <p><a routerLink="/farming">Farming calculator</a></p>
              <p><a routerLink="/crafts">My crafts</a></p>
            </div>
            <div class="footer-column">
              <p><b>Market</b></p>
              <p><a routerLink="/pricecheck">Albion Market Prices</a></p>
              <p><a routerLink="/pricefavorites">Favorite Prices</a></p>
              <p><a routerLink="/topitems">Top Traded Items</a></p>
            </div>
            <div class="footer-column">
              <p><b>Trading</b></p>
              <p><a routerLink="/flipper">Black Market Flipper</a></p>
              <p><a routerLink="/traderoutes">Trade Routes Finder</a></p>
              <p><a routerLink="/transport">Transport Calculator</a></p>
            </div>
            <div class="footer-column">
              <p><b>PVP and Meta Items</b></p>
              <p><a routerLink="/metaitems">Meta Items</a></p>
              <p><a routerLink="/itempower">Item Power Price Checker</a></p>
              <p><a routerLink="/repaircost">Repair Cost Calculator</a></p>
            </div>
            <div class="footer-column">
              <p><b>Knowledge Base</b></p>
              <p><a routerLink="/articles">Articles and Tutorials</a></p>
              <p><a routerLink="/albion-map">Albion Online Map</a></p>
              <p><a routerLink="/faq">FAQ</a></p>
              <p><a routerLink="/changelog">Change Log</a></p>
            </div>
            <div class="footer-column">
              <p><b>Miscelanea</b></p>
              <p><a routerLink="/support-us">Support AFM</a></p>
              <p><a routerLink="/privacy-policy">Privacy Policy</a></p>
              <p><a routerLink="/feedback">Feedback / Contact</a></p>
            </div>
          </div>
          <p>© 2025 Albion Free Market</p>
          <p>Albion Free Market is not affiliated with Albion Online or Sandbox Interactive GmbH</p>
          <p>All Albion Online Assets are property of Sandbox Interactive GmbH.</p>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
