import { ApplicationConfig, importProvidersFrom, provideExperimentalZonelessChangeDetection, SecurityContext } from '@angular/core';
import { RouterModule, provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { HttpClient, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';

import { DecimalPipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SmallNumberPipe } from './pipes/small-number/small-number.pipe';
import { provideMarkdown } from 'ngx-markdown';
import { provideClientHydration, withEventReplay, withNoHttpTransferCache } from '@angular/platform-browser';

import { authInterceptor, userIdInterceptor } from './shared/interceptors/http-interceptors';

export const appConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    importProvidersFrom(RouterModule.forRoot(routes), BrowserAnimationsModule),
    provideRouter(routes),
    provideClientHydration(withEventReplay(), withNoHttpTransferCache()),
    provideAnimations(),
    provideHttpClient(withFetch(), withInterceptors([authInterceptor, userIdInterceptor])),
    provideMarkdown({ loader: HttpClient, sanitize: SecurityContext.NONE }),
    [DecimalPipe, SmallNumberPipe],
    MatSnackBar,
  ],
};
