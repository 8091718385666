import { Routes } from '@angular/router';

const baseUrl = 'https://albionfreemarket.com';

export const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Albion Free Market - Albion Online Market Tools',
      description: 'Albion Online Market Prices, Crafting, Refining and Farming Calculator, Black Market Flipper, Meta Items and much more!',
      robots: '',
      canonical: `${baseUrl}/`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('../app/pages/home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'pricecheck',
    data: {
      title: 'Albion Online Market Prices',
      description: 'Albion Online Market Prices Checker. Get real time prices for items in Albion Online.',
      robots: '',
      canonical: `${baseUrl}/pricecheck`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/price-check/price-check.component').then((mod) => mod.PriceCheckComponent),
  },
  {
    path: 'pricecheck/:uniqueName',
    data: {
      title: 'Albion Online Market Prices - :uniqueName',
      description: 'Albion Online Market Prices Checker for :uniqueName. Get real time prices for items in Albion Online.',
      robots: '',
      canonical: `${baseUrl}/pricecheck/:uniqueName`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/price-check/price-check.component').then((mod) => mod.PriceCheckComponent),
  },
  {
    path: 'pricefavorites',
    data: {
      title: 'Albion Online Market Prices Favorites',
      description: 'Albion Online User Price Favorites Lists. Create and manage your favorite items lists.',
      robots: '',
      canonical: `${baseUrl}/pricefavorites`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/price-favorites/price-favorites.component').then((mod) => mod.PriceFavoritesComponent),
  },
  {
    path: 'flipper',
    data: {
      title: 'Albion Online Market and Black Market Flipper',
      description: 'Albion Online Market and Black Market Flipper. Best way to make easy money on Albion Online!',
      robots: '',
      canonical: `${baseUrl}/flipper`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/flipper/flipper.component').then((mod) => mod.FlipperComponent),
  },
  {
    path: 'yourflips',
    data: {
      title: 'Albion Online User Flips',
      description: 'Albion Online Market Tools.',
      robots: '',
      canonical: `${baseUrl}/yourflips`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/your-flips/your-flips.component').then((mod) => mod.YourFlipsComponent),
  },
  {
    path: 'crafts',
    data: {
      title: 'Albion Online User Crafts',
      description: 'Albion Online User Crafts. Follow your crafting progress and profits.',
      robots: '',
      canonical: `${baseUrl}/crafts`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/crafts/crafts.component').then((mod) => mod.CraftsComponent),
  },
  {
    path: 'repaircost',
    data: {
      title: 'Albion Online Repair Cost Calculator',
      description: 'Albion Online Repair Cost Calculator. Calculate the cost of repairing items in Albion Online.',
      robots: '',
      canonical: `${baseUrl}/repaircost`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/repair-cost/repair-cost.component').then((mod) => mod.RepairCostComponent),
  },
  {
    path: 'repaircost/:uniqueName',
    data: {
      title: 'Albion Online Repair Cost Calculator - :uniqueName',
      description: 'Albion Online Repair Cost Calculator for :uniqueName. Calculate the cost of repairing items in Albion Online.',
      robots: 'noindex',
      canonical: `${baseUrl}/repaircost/:uniqueName`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/repair-cost/repair-cost.component').then((mod) => mod.RepairCostComponent),
  },
  {
    path: 'journals',
    data: {
      title: 'Albion Online Labourer Journal Calculator',
      description: 'Albion Online Labourer Profit Calculator.',
      robots: '',
      canonical: `${baseUrl}/journals`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/journals/journals.component').then((mod) => mod.JournalsComponent),
  },
  {
    path: 'itempower',
    data: {
      title: 'Albion Online Item Power Price Comparison',
      description: 'Albion Online Item Power Price Checker. Compare item prices based on their power level.',
      robots: '',
      canonical: `${baseUrl}/itempower`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/item-power/item-power.component').then((mod) => mod.ItemPowerComponent),
  },
  {
    path: 'itempower/:uniqueName',
    data: {
      title: 'Albion Online Item Power Price Comparison - :uniqueName',
      description: 'Albion Online Item Power Price Checker for :uniqueName. Compare item prices based on their power level.',
      robots: 'noindex',
      canonical: `${baseUrl}/itempower/:uniqueName`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/item-power/item-power.component').then((mod) => mod.ItemPowerComponent),
  },
  {
    path: 'articles',
    data: {
      title: 'Albion Online Tutorials and Articles',
      description: 'Albion Online articles and tutorials.',
      robots: '',
      canonical: `${baseUrl}/articles`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/articles/articles.component').then((mod) => mod.ArticlesComponent),
  },
  {
    path: 'albion-map',
    data: {
      title: 'Albion Online Map',
      description: 'Albion Online maps and routes finder.',
      robots: '',
      canonical: `${baseUrl}/albion-map`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/albion-map/albion-map.component').then((mod) => mod.AlbionMapComponent),
  },
  {
    path: 'articles/view/:id',
    data: {
      title: ':id',
      description: ':id',
      robots: '',
      canonical: `${baseUrl}/articles/view/:id`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/articles-view/articles-view.component').then((mod) => mod.ArticlesViewComponent),
  },
  {
    path: 'metaitems',
    data: {
      title: 'Albion Online Meta Items',
      description: 'Albion Online Most Used / Meta Items. Know the most used items ins Albion Online PVP and the best meta builds.',
      robots: '',
      canonical: `${baseUrl}/metaitems`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/meta-tems-new/meta-tems-new.component').then((mod) => mod.MetaTemsNewComponent),
  },
  {
    path: 'topitems',
    data: {
      title: 'Albion Online Most Traded Items',
      description: 'Albion Online Most Traded Items. Know the most sold and bought items ins Albion Online.',
      robots: '',
      canonical: `${baseUrl}/topitems`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/top-items/top-items.component').then((mod) => mod.TopItemsComponent),
  },
  {
    path: 'traderoutes',
    data: {
      title: 'Albion Online Trade Routes Finder',
      description: 'Albion Online Trade Routes. Find out where to buy and sell items at a profit.',
      robots: '',
      canonical: `${baseUrl}/traderoutes`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/trade-routes/trade-routes.component').then((mod) => mod.TradeRoutesComponent),
  },
  {
    path: 'crafting',
    data: {
      title: 'Albion Online Crafting And Refining Calculator',
      description: 'Albion Online Crafting And Refining Calculator. Calculate the cost and profit of crafting and refining items in Albion Online.',
      robots: '',
      canonical: `${baseUrl}/crafting`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/crafting/crafting.component').then((mod) => mod.CraftingComponent),
  },
  {
    path: 'crafting/:uniqueName',
    data: {
      title: 'Albion Online Crafting And Refining Calculator - :uniqueName',
      description:
        'Albion Online Crafting And Refining Calculator for :uniqueName. Calculate the cost and profit of crafting and refining items in Albion Online.',
      robots: 'noindex',
      canonical: `${baseUrl}/crafting/:uniqueName`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/crafting/crafting.component').then((mod) => mod.CraftingComponent),
  },
  {
    path: 'farming',
    data: {
      title: 'Albion Online Framing and Breeding Calculator',
      description: 'Albion Online Farming and Breeding Calculator. Calculate the profit of farming in Albion Online.',
      robots: '',
      canonical: `${baseUrl}/farming`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/farm-calculator/farm-calculator.component').then((mod) => mod.FarmCalculatorComponent),
  },
  {
    path: 'transport',
    data: {
      title: 'Albion Online Transport Calculator',
      description: 'Albion Online Transport Calculator. Find the best trade routes and calculate the profit of transporting items in Albion Online.',
      robots: '',
      canonical: `${baseUrl}/transport`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/transport/transport.component').then((mod) => mod.TransportComponent),
  },
  {
    path: 'transport/:uniqueName',
    data: {
      title: 'Albion Online Transport Calculator - :uniqueName',
      description:
        'Albion Online Transport Calculator for :uniqueName. Find the best trade routes and calculate the profit of transporting items in Albion Online.',
      robots: 'noindex',
      canonical: `${baseUrl}/transport/:uniqueName`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/transport/transport.component').then((mod) => mod.TransportComponent),
  },
  {
    path: 'faq',
    title: 'Albion Free Market FAQ',
    data: {
      description: 'Albion Free Market FAQ. Frequently Asked Questions about Albion Free Market.',
      robots: '',
      canonical: `${baseUrl}/faq`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/faq/faq.component').then((mod) => mod.FaqComponent),
  },
  {
    path: 'changelog',
    data: {
      title: 'Albion Free Market Change Log',
      description: 'Albion Free Market Change Log. Check the latest updates and changes on Albion Free Market.',
      robots: '',
      canonical: `${baseUrl}/changelog`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/change-log/change-log.component').then((mod) => mod.ChangeLogComponent),
  },
  {
    path: 'feedback',
    data: {
      title: 'Albion Free Market Feedback',
      description: 'Albion Free Market Feedback. Send us your feedback and help us improve Albion Free Market.',
      robots: '',
      canonical: `${baseUrl}/feedback`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/feedback/feedback.component').then((mod) => mod.FeedbackComponent),
  },
  {
    path: 'privacy-policy',
    data: {
      title: 'Albion Free Market Privacy',
      description: 'Albion Free Market Privacy.',
      robots: '',
      canonical: `${baseUrl}/privacy-policy`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages//privacy/privacy.component').then((mod) => mod.PrivacyComponent),
  },
  {
    path: 'support-us',
    data: {
      title: 'Support Albion Free Market',
      description: 'Support Albion Free Market. Help us keep the website running and improve our services.',
      robots: '',
      canonical: `${baseUrl}/support-us`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/support-us/support-us.component').then((mod) => mod.SupportUsComponent),
  },
  {
    path: 'charts',
    data: {
      title: 'Albion Online Price Charts',
      description: 'Albion Online Price Charts. Check the price history of items in Albion Online.',
      robots: 'noindex',
      canonical: `${baseUrl}/charts`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/charts/charts.component').then((mod) => mod.ChartsComponent),
  },
  {
    path: 'charts/:uniqueName',
    data: {
      title: 'Albion Online Price Charts - :uniqueName',
      description: 'Albion Online Price Charts for :uniqueName. Check the price history of items in Albion Online.',
      robots: 'noindex',
      canonical: `${baseUrl}/charts/:uniqueName`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/charts/charts.component').then((mod) => mod.ChartsComponent),
  },
  {
    path: 'identifiers',
    data: {
      title: 'Albion Online Data Project Identifiers Checker',
      description: 'Albion Online Data Project Identifiers Checker. Look up item identifiers used in the Albion Online Data Project.',
      robots: '',
      canonical: `${baseUrl}/identifiers`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/identifiers/identifiers.component').then((mod) => mod.IdentifiersComponent),
  },
  {
    path: 'identifiers/:identifier',
    data: {
      title: 'Albion Online Data Project Identifiers Checker - :identifier',
      description: 'Albion Online Data Project Identifiers Checker for :identifier. Look up item identifiers used in the Albion Online Data Project.',
      robots: 'noindex',
      canonical: `${baseUrl}/identifiers/:identifier`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/identifiers/identifiers.component').then((mod) => mod.IdentifiersComponent),
  },
  {
    path: 'not-found',
    data: {
      title: 'Not Found',
      description: 'Page not found.',
      robots: '',
      canonical: `${baseUrl}/not-found`,
      image: `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`,
    },
    loadComponent: () => import('src/app/pages/not-found/not-found.component').then((mod) => mod.NotFoundComponent),
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];
