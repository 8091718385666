import { inject } from '@angular/core';
import { PrerenderFallback, RenderMode, ServerRoute } from '@angular/ssr';
import { ArticleService } from './services/articles/article.service';
import { take } from 'rxjs';

export const serverRoutes: ServerRoute[] = [
  {
    path: '',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'pricecheck',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'pricecheck/:uniqueName',
    renderMode: RenderMode.Prerender,
    // fallback: PrerenderFallback.Client,
    async getPrerenderParams() {
      return priceCheckUniqueNames.map((uniqueName) => ({ uniqueName }));
    },
  },
  {
    path: 'pricefavorites',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'flipper',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'yourflips',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'crafts',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'repaircost',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'journals',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'itempower',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'albion-map',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'articles',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'articles/view/:id',
    renderMode: RenderMode.Prerender,
    // fallback: PrerenderFallback.Client,
    async getPrerenderParams() {
      return articles.map((article) => ({ id: article }));
    },
  },
  {
    path: 'metaitems',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'topitems',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'traderoutes',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'crafting',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'farming',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'transport',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'faq',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'changelog',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'feedback',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'privacy-policy',
    renderMode: RenderMode.Prerender,
  },
  {
    path: 'support-us',
    renderMode: RenderMode.Prerender,
  },
  {
    path: '**',
    renderMode: RenderMode.Client,
  },
];

const articles = [
  'albion-online-solo-weapons-analysis-europe',
  'understanding-item-power-in-albion-online',
  "albion-online-smuggler's-dens-and-smuggler's-network-tutorial",
  'the-albion-online-data-project-client-tutorial',
  'mastering-the-art-of-farming-in-albion-online',
  'albion-online-hellgates-guide',
  'albion-online-black-market-flipper-tutorial',
  'transporting-guide',
];

export const priceCheckUniqueNames = [
  'T4_SKILLBOOK_STANDARD',
  'UNIQUE_GVGTOKEN_GENERIC',
  'T6_BAG_INSIGHT',
  'T8_MEAL_STEW',
  'T6_BAG',
  'T7_BAG_INSIGHT',
  'T5_BAG_INSIGHT',
  'T8_BAG',
  'T5_MOUNT_COUGAR_KEEPER',
  'T8_BAG_INSIGHT',
  'T7_BAG',
  'QUESTITEM_TOKEN_AVALON',
  'T4_CAPEITEM_FW_LYMHURST',
  'T7_MEAL_OMELETTE',
  'T5_BAG',
  'T7_POTION_REVIVE',
  'T4_MOUNT_GIANTSTAG',
  'TREASURE_RITUAL_RARITY3',
  'T6_MOUNT_DIREWOLF',
  'T6_LEATHER',
  'T8_POTION_COOLDOWN',
  'T7_MEAL_OMELETTE_AVALON',
  'T8_POTION_CLEANSE',
  'T8_STONEBLOCK',
  'T8_MOUNT_MAMMOTH_TRANSPORT',
  'T4_CAPEITEM_FW_MARTLOCK',
  'T6_ARMOR_LEATHER_SET3',
  'T6_ARMOR_LEATHER_SET1',
  'T6_CAPEITEM_AVALON',
  'T7_STONEBLOCK',
  'T4_RELIC',
  'T5_RELIC',
  'T6_SOUL',
  'T6_HIDE',
  'T8_MOUNT_DIREBEAR_FW_FORTSTERLING_ELITE',
  'T8_LEATHER',
  'T6_RELIC',
  'T7_MEAL_PIE',
  'T6_CAPE',
  'T1_FACTION_FOREST_TOKEN_1',
  'T3_MOUNT_HORSE',
  'T6_POTION_HEAL',
  'T6_ARMOR_CLOTH_SET2',
  'T6_METALBAR',
  'T7_MEAL_ROAST_FISH',
  'T4_BAG_INSIGHT',
  'T6_MOUNT_GIANTSTAG_MOOSE',
  'T8_MEAL_STEW_FISH',
  'T8_PLANKS',
  'TREASURE_TRIBAL_RARITY3',
  'T6_PLANKS',
  'T8_MOUNT_DIREBOAR_FW_LYMHURST_ELITE',
  'T4_CAPEITEM_FW_CAERLEON',
  'T4_CAPEITEM_UNDEAD',
  'TREASURE_CEREMONIAL_RARITY3',
  'T4_CAPEITEM_AVALON',
  'TREASURE_KNOWLEDGE_RARITY3',
  'T4_CAPEITEM_FW_THETFORD',
  'TREASURE_SILVERWARE_RARITY3',
  'T7_POTION_STONESKIN',
  'T6_MOUNT_FROSTRAM_ADC',
  'TREASURE_DECORATIVE_RARITY3',
  'T6_ARMOR_PLATE_SET3',
  'T6_CLOTH',
  'T5_LEATHER',
  'T4_SHOES_CLOTH_ROYAL',
  'T6_ARMOR_CLOTH_SET1',
  'T5_HIDE',
  'T6_ARMOR_CLOTH_SET3',
  'T6_ARMOR_PLATE_SET2',
  'T7_HIDE',
  'T1_FACTION_MOUNTAIN_TOKEN_1',
  'T5_SOUL',
  'T8_METALBAR',
  'T7_PLANKS',
  'T6_ARMOR_PLATE_SET1',
  'T1_FISHCHOPS',
  'T7_LEATHER',
  'T6_ARMOR_LEATHER_SET2',
  'T5_MOUNT_DIREBOAR_FW_LYMHURST',
  'T8_ARMOR_LEATHER_SET3',
  'T1_FACTION_SWAMP_TOKEN_1',
  'T8_ARMOR_LEATHER_SET1',
  'T5_MOUNT_ARMORED_HORSE',
  'T7_MOUNT_HUSKY_ADC',
  'T6_FIBER',
  'T6_METALBAR_LEVEL2@2',
  'T5_METALBAR',
  'T4_HIDE_LEVEL3@3',
  'T4_ARMOR_CLOTH_FEY',
  'T4_BAG',
  'T6_ORE',
  'T6_HEAD_LEATHER_SET3',
  'T1_FACTION_HIGHLAND_TOKEN_1',
  'T5_ARMOR_LEATHER_SET1',
  'T8_2H_TOOL_KNIFE',
  'T6_PLANKS_LEVEL2@2',
  'T5_ARMOR_LEATHER_SET3',
  'T6_LEATHER_LEVEL2@2',
  'T4_PLANKS_LEVEL4@4',
  'T6_STONEBLOCK',
  'T3_POTION_MOB_RESET',
  'T4_LEATHER_LEVEL3@3',
  'T1_CARROT',
  'T8_CLOTH',
  'T5_METALBAR_LEVEL3@3',
  'T6_RUNE',
  'T6_METALBAR_LEVEL3@3',
  'T8_CAPEITEM_AVALON',
  'UNIQUE_MOUNT_GIANT_HORSE_ADC',
  'QUESTITEM_TOKEN_ROYAL_T4',
  'T4_CLOTH_LEVEL3@3',
  'T1_FACTION_STEPPE_TOKEN_1',
  'T4_2H_SHAPESHIFTER_MORGANA',
  'UNIQUE_MOUNT_UNDEAD_DIREBOAR_ADC',
  'T5_PLANKS',
  'T6_PLANKS_LEVEL3@3',
  'T6_SHOES_PLATE_SET1',
  'T5_PLANKS_LEVEL3@3',
  'T5_CAPE',
  'T4_SOUL',
  'T7_CAPE',
  'T5_MEAL_SOUP',
  'T8_FARM_MAMMOTH_BABY',
  'T8_ARMOR_PLATE_SET3',
  'T4_FIBER_LEVEL3@3',
  'T6_LEATHER_LEVEL1@1',
  'T7_CLOTH',
  'T6_HIDE_LEVEL1@1',
  'T4_CAPEITEM_FW_FORTSTERLING',
  'T7_METALBAR',
  'T6_METALBAR_LEVEL1@1',
  'T5_SHOES_CLOTH_ROYAL',
  'T5_FIBER',
  'T7_RELIC',
  'T8_HIDE',
  'UNIQUE_UNLOCK_SKIN_GIANTSTAG_XMAS',
  'T5_MOUNT_GREYWOLF_FW_CAERLEON',
  'T4_METALBAR_LEVEL4@4',
  'T6_SHOES_LEATHER_SET1',
  'T5_POTION_MOB_RESET',
  'T6_ARMOR_LEATHER_HELL',
  'T8_2H_TOOL_SICKLE',
  'T4_PLANKS_LEVEL3@3',
  'T5_CLOTH',
  'T5_LEATHER_LEVEL3@3',
  'T8_ARMOR_CLOTH_SET2',
  'T6_HEAD_PLATE_SET1',
  'T4_LEATHER',
  'T8_2H_TOOL_PICK',
  'T8_ARMOR_LEATHER_SET2',
  'T8_HEAD_LEATHER_SET3',
  'T6_WOOD',
  'T1_FISHSAUCE_LEVEL2',
  'T8_2H_TOOL_AXE',
  'T8_ARMOR_CLOTH_SET1',
  'T6_PLANKS_LEVEL1@1',
  'T5_ARMOR_LEATHER_HELL',
  'T5_ARMOR_CLOTH_SET2',
  'T7_ARMOR_LEATHER_SET1',
  'T7_MEAL_ROAST',
  'UNIQUE_FURNITUREITEM_MORGANA_WEAPONCRATE_A',
  'T5_ORE',
  'T6_HEAD_PLATE_SET3',
  'T7_SOUL',
  'T4_METALBAR',
  'T6_CLOTH_LEVEL2@2',
  'T6_ORE_LEVEL1@1',
  'T6_ORE_LEVEL2@2',
  'T4_METALBAR_LEVEL3@3',
  'T1_FISHSAUCE_LEVEL1',
  'T3_WHEAT',
  'T6_HEAD_PLATE_SET2',
  'T7_WOOD',
  'T7_ARMOR_LEATHER_SET3',
  'T7_JOURNAL_FISHING_FULL',
  'T7_FIBER',
  'T6_SHOES_LEATHER_SET2',
  'T5_HIDE_LEVEL2@2',
  'T6_CLOTH_LEVEL1@1',
  'QUESTITEM_TOKEN_ROYAL_T5',
  'T4_ARMOR_LEATHER_ROYAL',
  'T6_SHOES_PLATE_SET3',
  'T6_LEATHER_LEVEL3@3',
  'T4_PLANKS',
  'T6_POTION_COOLDOWN',
  'T8_CAPE',
  'T4_HEAD_CLOTH_HELL',
  'T6_HEAD_CLOTH_SET1',
  'T6_HEAD_CLOTH_SET2',
  'T4_WOOD_LEVEL3@3',
  'T6_SHOES_PLATE_SET2',
  'T8_ARMOR_PLATE_SET2',
  'T5_STONEBLOCK',
  'T1_SEAWEED',
  'T1_FACTION_CAERLEON_TOKEN_1',
  'T8_METALBAR_LEVEL3@3',
  'T6_HEAD_LEATHER_SET2',
  'T4_CAPEITEM_MORGANA',
  'T7_ARMOR_CLOTH_SET2',
];
