<div class="cookie-consent">
  <h2>Cookie Preferences</h2>
  <div class="content">
    <p class="description">Choose your cookies preferences:</p>

    <form [formGroup]="consentForm">
      @for (type of cookieTypes; track type.key) {
        <div class="cookie-option">
          <div class="option-header">
            <mat-slide-toggle [formControlName]="type.key">
              {{ type.label }}
            </mat-slide-toggle>
          </div>
          <p class="option-description">{{ type.description }}</p>
        </div>
      }
    </form>
  </div>

  <div class="actions">
    <button mat-button (click)="save()">Save Preferences</button>
    <button mat-raised-button color="primary" (click)="acceptAll()">Accept All</button>
  </div>
</div>
