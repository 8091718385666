import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { JsonReaderService } from '../json-reader/json-reader.service';
import { combineLatest, filter, switchMap, take } from 'rxjs';
import { ArticleService } from '../articles/article.service';
import { priceCheckUniqueNames } from 'src/app/app.routes.server';
import { AnalyticsService } from '../analytics/analytics.service';
import { AfmLogsService } from '../afm-logs/afm-logs.service';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private meta: Meta,
    private titleService: Title,
    private jsonReaderService: JsonReaderService,
    private articlesService: ArticleService,
    private analyticsService: AnalyticsService,
    private afmLogsService: AfmLogsService,
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.registerNavigationEvents();
  }

  defaultTitle = 'Albion Free Market';
  defaultDescription = 'Albion Online Market Tools.';
  defaultRobots = 'all';
  defaultImage = `https://assets.albiononline.com/uploads/media/default/media/6a42c136eb88597ea156e603b9927b3ab0968dab.jpeg`;

  registerNavigationEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        switchMap(() => {
          const rt = this.getChild(this.activatedRoute);
          return combineLatest([rt.paramMap, rt.data, this.jsonReaderService.usNameMappingsHasLoaded$, this.articlesService.articlesHaveLoaded$]).pipe(
            filter(([params, data, namesLoaded, articlesLoaded]) => namesLoaded && articlesLoaded),
            take(1)
          );
        })
      )
      .subscribe(([params, data]) => {
        if (params.keys.length > 0 && !!params.keys[0]) {
          const key = params.keys[0];
          const value = params.get(key);

          if (key === 'uniqueName') {
            let usName = value;
            if (value) {
              usName = this.jsonReaderService.usNameMappings[value];
            }

            if (data['title']) {
              data['title'] = data['title'].replace(`:${key}`, usName);
            }
            //if the title ends with ' - ', remove it, this is a hack cause we need to fix trailing slash?
            if (data['title'].endsWith(' - ')) {
              data['title'] = data['title'].slice(0, -3);
            }
            if (data['description']) {
              data['description'] = data['description'].replace(`:${key}`, usName);
            }
            if (data['canonical']) {
              data['canonical'] = data['canonical'].replace(`:${key}`, value);
            }
          }

          if (data['canonical'].includes('/articles/view')) {
            let id = '';

            if (key === 'id') {
              if (value) {
                id = value;
              }
            }

            const articleData = this.articlesService.getArticleInstant(id);

            if (articleData) {
              if (data['title']) {
                data['title'] = data['title'].replace(`:${key}`, articleData.title);
              }
              if (data['description']) {
                data['description'] = data['description'].replace(`:${key}`, articleData.summary);
              }
              if (data['canonical']) {
                data['canonical'] = data['canonical'].replace(`:${key}`, value);
              }
            }
          }
        }

        // TWITTER
        this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
        this.meta.updateTag({ name: 'twitter:site', content: '@albionfreemark' });

        // OG:TYPE
        this.meta.updateTag({ property: 'og:type', content: 'website' });

        // TITLE
        this.titleService.setTitle(data['title'] || this.defaultTitle);
        this.analyticsService.sendPageViewEvent();
        this.afmLogsService.logSiteUse(`Page view: ${data['title'] || this.defaultTitle}`);
        this.meta.updateTag({
          property: 'og:title',
          content: data['title'] || this.defaultTitle,
        });

        // DESCRIPTION
        this.meta.updateTag({
          name: 'description',
          content: data['description'] || this.defaultDescription,
        });
        this.meta.updateTag({
          property: 'og:description',
          content: data['description'] || this.defaultDescription,
        });

        // ROBOTS
        if (params.keys.length > 0 && !!params.keys[0] && data['canonical'].includes('/pricecheck/')) {
          const key = params.keys[0];
          const value = params.get(key);
          this.meta.updateTag({
            name: 'robots',
            content: !!value && !priceCheckUniqueNames.includes(value) ? 'noindex' : 'all',
          });
        } else {
          this.meta.updateTag({
            name: 'robots',
            content: data['robots'] || this.defaultRobots,
          });
        }

        // IMAGE
        this.meta.updateTag({
          property: 'og:image',
          content: data['image'] || this.defaultImage,
        });

        // URL AND CANONICAL
        const linkElement = this.doc.querySelector('link[rel="canonical"]') as HTMLLinkElement;
        if (data['canonical']) {
          this.meta.updateTag({
            property: 'og:url',
            content: data['canonical'],
          });
          if (linkElement) {
            linkElement.href = data['canonical'];
          } else {
            const newLinkElement = this.doc.createElement('link');
            newLinkElement.rel = 'canonical';
            newLinkElement.href = data['canonical'];
            this.doc.head.appendChild(newLinkElement);
          }
        } else {
          if (linkElement) {
            linkElement.remove();
          }
        }
      });
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
