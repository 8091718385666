import { Component, Input, HostBinding, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LocalizationLanguage } from '../../models/localization.model';
import { getAllLocalizationLanguages } from '../../constants/localization.constant';
import { AuthService } from 'src/app/services/auth-service/auth-service.service';
import { UserSettingsService } from 'src/app/services/user-settings/user-settings.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogComponent } from '../dialog/dialog.component';
import { getAllServers, getServerName } from '../../constants/servers.constant';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AfmBeService } from 'src/app/services/afm-be/afm-be.service';
import { OkDialogComponent } from '../ok-dialog/ok-dialog.component';
import { User } from 'firebase/auth';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { CookieConsentDialogComponent } from 'src/app/shared/components/cookie-consent-dialog/cookie-consent-dialog.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoadingBarService } from 'src/app/services/loading-bar/loading-bar.service';

@Component({
  selector: 'app-main-page',
  imports: [
    CommonModule,
    RouterOutlet,
    RouterModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatBadgeModule,
    MatDividerModule,
    MatTooltipModule,
    MatProgressBarModule,
  ],
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent implements OnInit, OnDestroy {
  user: User | null | undefined = null;
  get userInitials(): string {
    return (
      this.user?.displayName
        ?.split(' ')
        .map((n) => n[0] + '.')
        .join(' ') ?? ''
    );
  }
  _userSettingsService: UserSettingsService;
  @HostBinding('class') public cssClass: string;

  @Input() appTitle: string = '';
  languages: LocalizationLanguage[] = getAllLocalizationLanguages();
  disableColorModeButton: boolean = false;
  servers: { id: string; name: string }[] = getAllServers();

  subscriptions: Subscription[] = [];

  constructor(
    public router: Router,
    private userSettingsService: UserSettingsService,
    private breakpointObserver: BreakpointObserver,
    public afmBeService: AfmBeService,
    public authService: AuthService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private analyticsService: AnalyticsService,
    private dialog: MatDialog,
    public loadingBarService: LoadingBarService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this._userSettingsService = userSettingsService;
    this.cssClass = 'albion-light-mode';
    this.subscriptions.push(authService.user$.subscribe((loggedUser) => (this.user = loggedUser)));
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const storedConsent = this.analyticsService.getStoredConsent();
      if (!storedConsent) {
        this.showConsentDialog();
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.languages = [];
    this.servers = [];
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay(1)
  );

  closeDrawer(drawer: MatSidenav) {
    this.isHandset$.pipe(take(1)).subscribe((isHandset) => {
      if (isHandset) {
        drawer.close();
      }
    });
  }
  async changeColorMode(): Promise<void> {
    this.disableColorModeButton = true;

    if (this._userSettingsService.getUserTheme() === 'albion-dark-theme') {
      await this.userSettingsService.setUserTheme('albion-light-theme');
    } else {
      await this.userSettingsService.setUserTheme('albion-dark-theme');
    }

    setTimeout(() => {
      this.disableColorModeButton = false;
    }, 5000);
  }
  async serverSelected(server: string): Promise<void> {
    if (server) {
      if (server !== this._userSettingsService.getUserServer()) {
        await this._userSettingsService.setUserServer(server);
      }
    }
  }
  async languageSelected(language: LocalizationLanguage): Promise<void> {
    if (language) {
      if (language !== this._userSettingsService.getUserLanguage()) {
        await this._userSettingsService.setUserLanguage(language);
      }
    }
  }

  async loginWithGoogle() {
    this.authService.googleSignIn();
  }
  async logout() {
    await this.authService.signOut();
    this.router.navigate(['/']);
  }

  async deleteAccount(): Promise<void> {
    if (this.userSettingsService.isLoggedIn) {
      if (!this.afmBeService.userDataIsLoaded()) {
        await this.afmBeService.updateAllUserData();
      }
      if (this.afmBeService.afmStripeUserData().hasNonCanceledSubscriptions) {
        const subsDialogRef = this.matDialog.open(OkDialogComponent, {
          width: '250px',
          data: {
            title: 'You have active subscriptions!',
            message: `You need to cancel all you subscriptions before deleting your account! Otherwise you will be charged again! 
              You'll be redirected to the Support AFM page to cancel your subscriptions!`,
          },
        });

        subsDialogRef.afterClosed().subscribe(async (result) => {
          if (result) {
            this.router.navigate(['/support-us']);
          }
        });
      } else {
        const confirmDialogRef = this.matDialog.open(DialogComponent, {
          width: '250px',
          data: {
            title: 'Confirm Deletion',
            message: `Are you sure you want to delete <strong>${this.userInitials}</strong>'s account? 
              This will remove all the data and you wont be able to ever recover it.'`,
          },
        });

        confirmDialogRef.afterClosed().subscribe(async (result) => {
          if (result) {
            const dialogRef2 = this.matDialog.open(DialogComponent, {
              width: '250px',
              data: {
                title: 'Confirm Deletion',
                message: `Are you REALLY sure? There's no going back! Last chance!`,
              },
            });

            dialogRef2.afterClosed().subscribe(async (result) => {
              if (result) {
                await this.userSettingsService.deleteAccount();
                this.router.navigate(['/']);
              }
            });
          }
        });
      }
    } else {
      this.snackBar.open(`You must be Logged In to delete your account!`, undefined, { duration: 2000 });
    }
  }

  public showConsentDialog(): void {
    const dialogRef = this.dialog.open(CookieConsentDialogComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.analyticsService.updateConsent(result);
      }
    });
  }

  getServerName(id: string): string {
    return getServerName(id);
  }
}
