import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ConsentData, defaultConsentData } from '../../models/consent-data';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CommonModule } from '@angular/common';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'app-cookie-consent-dialog',
  templateUrl: './cookie-consent-dialog.component.html',
  styleUrls: ['./cookie-consent-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatSlideToggleModule, ReactiveFormsModule],
})
export class CookieConsentDialogComponent implements OnInit {
  consentForm: FormGroup;

  cookieTypes = [
    {
      key: 'analytics_storage',
      label: 'Analytics',
      description: 'Helps us understand how you use our site',
    },
    {
      key: 'ad_storage',
      label: 'Advertisement Storage',
      description: 'Stores data for advertising purposes',
    },
    {
      key: 'ad_user_data',
      label: 'Ad Personalization Data',
      description: 'Allows sending data for ad-related purposes',
    },
    {
      key: 'ad_personalization',
      label: 'Ad Personalization',
      description: 'Enables personalized ads for you',
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<CookieConsentDialogComponent>,
    private fb: FormBuilder,
    analyticsService: AnalyticsService
  ) {
    const currentConsent = analyticsService.getStoredConsent();
    // if theres no stored consent, lets set it as default

    console.log(currentConsent);
    const formGroup: any = {};
    this.cookieTypes.forEach((type) => {
      formGroup[type.key] = currentConsent
        ? currentConsent[type.key as keyof ConsentData] === 'granted'
        : defaultConsentData[type.key as keyof ConsentData] === 'granted';
    });
    this.consentForm = this.fb.group(formGroup);
  }

  ngOnInit() {
    this.dialogRef.updatePosition({ bottom: '10px', right: '10px' });
  }

  save(): void {
    const consent: ConsentData = {
      analytics_storage: this.consentForm.get('analytics_storage')?.value ? 'granted' : 'denied',
      ad_storage: this.consentForm.get('ad_storage')?.value ? 'granted' : 'denied',
      ad_user_data: this.consentForm.get('ad_user_data')?.value ? 'granted' : 'denied',
      ad_personalization: this.consentForm.get('ad_personalization')?.value ? 'granted' : 'denied',
    };
    this.dialogRef.close(consent);
  }

  acceptAll(): void {
    Object.keys(this.consentForm.controls).forEach((key) => {
      this.consentForm.get(key)?.setValue(true);
    });
    this.save();
  }
}
